<template>
  <div id="app" class="app">


    <div class="dashboard_main w-full flex mb-4">
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.count_clients" :text="$t('client_s')" icon="usergroup-add" class="w-1/3 mr-4 " style=""/>
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.total_requests" :text="$t('request_s')" icon="plus-square" class="w-1/3 mr-4" :bg-color="'#438655'" color="white"/>
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.completed_requests" icon="check-circle" class="w-1/3">
        <template slot="title">
          <div style="line-height: initial;">
            <div>
              {{$t('finish_s')}}
            </div>
            <div>
              {{$t('request_s').toLowerCase()}}
            </div>
          </div>
        </template>
      </dashboard-card>
    </div>
<!--    <card>-->
<!--      <AgGridTable-->
<!--          @open_drawer="open_drawer_request"-->
<!--          :column_defs="column_defs_create_request"-->
<!--          :end_point="'organizations/customer/'"-->
<!--          name_event_bus="customer_request_api_event_bus"/>-->
<!--    </card>-->
    <card>
      <custom-tabular ref="refCustomTabular" :column_defs="column_defs" :end_point="'organizations/customer_only_users/'" @open_drawer="open_drawer"/>
    </card>
    <client-and-request ref="refClientAndRequest" :title="$t('add_request_text')"/>
  </div>
</template>
<script>
import DashboardCard from "@/pages/MainPage/DashboardCard/DashboardCard";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import Card from "@/crm_components/card/Card";
import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import {mapActions, mapGetters} from "vuex";
import CustomTabular from "@/components/CustomTabular/CustomTabular.vue";
import {EventBus} from "@/configs/eventBus";

export default {
  name: "MainPage",
  components:{
    DashboardCard,
    AgGridTable,
    Card,
    ClientAndRequest,
    CustomTabular,
  },
  data(){
    return{
      data_ex:[
        {id:1, name:"Oli Bob", age:"12", col:"red", dob:""},
        {id:2, name:"Mary May", age:"1", col:"blue", dob:"14/05/1982"},
        {id:3, name:"Christine Lobowski", age:"42", col:"green", dob:"22/05/1982"},
        {id:4, name:"Brendon Philips", age:"125", col:"orange", dob:"01/08/1980"},
        {id:5, name:"Margret Marmajuke", age:"16", col:"yellow", dob:"31/01/1999"},
      ]
    }
  },
  mixins:[
    ColumnDefsCreateRequest,
    OpenDrawerRequestMixin,
    DeleteMixin,
  ],
  computed:{
    ...mapGetters({
      GET_STATISTICS_MAIN_PAGE:'client/GET_STATISTICS_MAIN_PAGE',
      GET_PAGES_FRONT:'user/GET_PAGES_FRONT',
      GET_PROFILE_COLUMN_DEFS:'user/GET_PROFILE_COLUMN_DEFS',
    }),
    con_fig(){
      console.log('this.GET_PAGES_FRONT', this.GET_PAGES_FRONT)
      if (this.GET_PAGES_FRONT){
        return this.GET_PAGES_FRONT.find(ele=>ele.code==='customer_request')
      }
    },
    column_defs(){

      console.log('this.con_fig', this.con_fig)
      if (this.con_fig){
        if (this.GET_PROFILE_COLUMN_DEFS){
          let filter_column_defs = this.GET_PROFILE_COLUMN_DEFS.filter(ele=>ele.pages_front === this.con_fig.uid)
          let list = []
          this.con_fig.column_defs.forEach((ele)=>{
            let c = filter_column_defs.find(item=>item.column_defs === ele.uid)
            if (c){
              list.push({
                ...ele,
                use:c.use,
                sort:c.sort,
              })
            }else{
              list.push({
                ...ele,
              })
            }

          })
          console.log('sadsadasdadsadsads')

          // let clone = this.column_defs_props.slice().sort((a, b) => a.sort - b.sort);
          return list.slice().sort((a, b) => a.sort - b.sort);
        }
      }
    }
  },
  methods:{
    ...mapActions({
      STATISTICS_MAIN_PAGE:'client/STATISTICS_MAIN_PAGE',
    }),
    open_drawer(data){
      EventBus.$emit('event_request_show_drawer', data.data, false);
    },
  },
  mounted() {
    this.STATISTICS_MAIN_PAGE()

  }
}
</script>

