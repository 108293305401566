<template>
  <card-form :color="color" :bg-color="bgColor">
    <div class="flex justify-center">
      <span class="number">{{number}}</span>
    </div>
    <div class="flex justify-center">
      <div v-if="text">
        <span class="text">{{text}}</span>
      </div>
      <div v-else>
        <div class="text">
          <slot name="title"></slot>
        </div>
      </div>
    </div>
    <a-icon :type="icon" class="icon"/>
  </card-form>
</template>

<script>
import CardForm from "@/pages/MainPage/DashboardCard/CardForm";
export default {
  name: "MainPage",
  components: {CardForm},
  props:{
    number:{
      type:Number,
      default:0
    },
    text:{
      type:String,
      default:null
    },
    icon:{
      type:String,
      default:''
    },
    bgColor: {
      type: String,
      default: '#f5f5f5'
    },
    color: {
      type: String,
      default: '#438655'
    }
  }
}
</script>

<style lang="scss">
.card-from-dashboard{
  .number {
    font-size: 100px;
    font-weight: bold;
  }
  .text {
    font-weight: 550;
    font-size: 34px;
    margin-left: 50px;
  }
  .icon {
    font-size: 50px;
    margin: -30px 0px 20px 20px;
    bottom: 0;
    position: absolute;
  }
}

</style>
